<template>
	<div id="cpm_landOrderDetail">
		<div class="el-content mb12" v-if="detail">
			<div class="order-steps">
				<a-steps :current="active">
				    <a-step title="买家下单1" :description="detail.create_time"/>
				    <a-step title="买家付款" :description="detail.pay_time"/>
				    <a-step title="商家发货" :description="detail.send_time"/>
				    <a-step title="订单完成" :description="detail.confirm_time"/>
				</a-steps>
			</div>
			<a-row class="order-info">
				<a-col  :span="12" style="border-right: 1px solid #f4f4f4;padding: 20px;">
					<div class="grid-content bg-purple">
						<div class="order-li">
							<div class="order-title">用户昵称 :</div><div  v-if="detail.account">{{detail.account.nickname}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">真实姓名 :</div><div>{{detail.name}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">联系电话 :</div><div>{{detail.mobile}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">价格 :</div><div>￥{{detail.buy_price}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">数量 :</div><div>x {{detail.buy_count}} {{detail.adopt.unit}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">付款方式 :</div><div>{{$util.payMethod(detail.pay_method)}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">买家备注:</div><div>{{detail.remark}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">商家备注 :</div><div>{{detail.manager_remark}}</div>	
						</div>
						<div class="order-li">
							<div class="order-title">配送方式 :</div><div>快递</div>	
						</div>
					</div>
				</a-col>
				<a-col :span="12" style="padding: 20px;">
					<div class="grid-content bg-purple-light">
						<div class="order-li">
							<div class="order-title">订单编号 :</div><div>{{detail.order_number}}</div>	
						</div>
						<div class="order-li" >
							<div class="order-title">订单状态 :</div>
							<div>
								<span class="order-status">{{$status.adoptOrderStatus(detail)}}</span>
							</div>	
						</div>
						<div class="order-li" >
							<com-cashback-status :order-id="detail.id" :order-type="3"></com-cashback-status>
						</div>
						<div class="order-li" style="margin: 30px 0 10px 0;">
							<span  v-has="{action:'wxapp_adopt_order_remark',plat:isShop}">
								<com-set-remark 
									btn-text="[备注]" 
									btn-type="link"
									btn-size="small" 
									remark-type="adopt-order"
									postApi="doSomethingByOrder" 
									:remarkValue="detail.manager_remark"
									:params="{
										pk:detail.id,
										type:5 ,
										order_type:10
									}" @success="()=>{getAdoptOrderDetail()}">
								</com-set-remark>
							</span>
							<a-button type="link" @click="printOrder">[打印订单]</a-button>
							<a-button type="link" @click="show.refund = true" v-if="detail.pay_status ==1 && detail.is_confirm !=1 && detail.is_refund == 0">[退款]</a-button>
							<com-pay-result :id="detail.id" :type="3"></com-pay-result>
							<a-button type="link" @click="downloadContract">[下载合同]</a-button>
							<com-select-gbdevice :order="detail" :set-type="3"></com-select-gbdevice>
						</div>
						<div class="f12">友情提示： 如果无法进行发货，请及时联系买家进行妥善处理</div>
					</div>
				</a-col>
			</a-row>
		</div>
		<div class="el-content" v-if="detail">
			<div class="kd-pacel">认养信息</div>
			<a-table :pagination="false" row-key="id" :data-source="detail.detail" :columns="[
				{title:'认养信息',dataIndex:'adopt',slots:{customRender:'adopt'}},
				{title:'编号(点击切换认养周期)',dataIndex:'cycle',slots:{customRender:'cycle'}},
				{title:'当前状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'时间信息',dataIndex:'time',slots:{customRender:'time'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #adopt="{record}">
					<div style="display: flex;">
						<a-image :src="record.cover" style="width: 30px;height: 30px;margin-right: 12px;"/>
						<span>{{ record.name}}</span>
					</div>
				</template>
				<template #cycle="{record}">
					<div style="cursor: pointer;">
						<a-tooltip title="点击查看该认养的周期信息">
							<span v-if="record.adopt_number" @click="changeDelivery(record)">{{record.adopt_number}}</span> 
							<span v-else>未分配</span> 
						</a-tooltip>
						<span class="opeartionSpan" @click="updateAdoptNumber(record)">[修改]</span>
					</div>
				</template>
				<template #status="{record}">
					<div v-has="{action:'wxapp_adopt_order_change_status',plat:isShop}" 
						v-if="record.adoption_status==1">待分配 
						<span class="opeartionSpan" @click="changeAdoptStatus(record.id,2)">[认养中]</span>
					</div>
					<div v-has="{action:'wxapp_adopt_order_change_status',plat:isShop}" 
						v-if="record.adoption_status==2">认养中 
						<span class="opeartionSpan" @click="changeAdoptStatus(record.id,3)">[已成熟]</span>
					</div>
					<div v-if="record.adoption_status==3">配送中</div>
					<div v-if="record.adoption_status==4">已完成</div>
				</template>
				<template #time="{record}">
					<div>认养时间：{{ record.adopt_time }}</div>
					<div>
						成熟时间：{{ record.ripe_time }}
						<a-button type="link" @click="updateRipeTime(record)">[更新]</a-button>
					</div>
				</template>
				<template #action="{record}">
					<a-space>
						<a-button
							v-has="{action:'wxapp_adopt_order_status',plat:isShop}" 
							type="primary" 
							size="small" 
							@click="getStatusList(record.id)">状态跟踪
						</a-button>
						<a-button
							v-has="{action:'wxapp_adopt_order_status_add',plat:isShop}" 
							type="primary" 
							size="small" 
							@click="addAdoptStatus(record)">新增状态
						</a-button>
					</a-space>
				</template>
			</a-table>
			
			<div style="display: flex;align-items: center;margin: 12px 0;">
				<div class="kd-pacel mb12">周期信息</div>
				<span>（认养编号：{{delivery_num}}）</span> 
				<a-button 
					v-has="{action:'wxapp_adopt_order_add_cycle',plat:isShop}" 
					type="primary" 
					@click="show.delivery_add = true">添加周期
				</a-button>
			</div>
			<a-table :pagination="false" row-key="id" :data-source="delivery" :columns="[
				{title:'每期编号',dataIndex:'cycle_number'},
				{title:'物流信息',dataIndex:'express',slots:{customRender:'express'}},
				{title:'配送时间',dataIndex:'time',slots:{customRender:'time'}},
				{title:'收货信息',dataIndex:'get',slots:{customRender:'get'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #express="{record}">
					<div v-if="record.express_company">{{record.express_company}}</div>
					<div v-if="record.express_no">{{record.express_no}}</div>
					<div v-if="record.runing">
						<a-tooltip >
							<template #title>
								<template v-if="record.runing.run_type == 'dada'">
									<div>配送员：{{record.runing.dm_name}}</div>
									<div>配送员电话：{{record.runing.dm_mobile}}</div>
								</template>
								<template v-else>
									<div v-if="record.runing.persion">配送员：{{record.runing.persion.name}}</div>
									<div v-if="record.runing.persion">配送员电话：{{record.runing.persion.mobile}}</div>
								</template>
								<div>取货时间：{{record.runing.pickup_time || '--'}}</div>
								<div>送达时间：{{record.runing.confirm_time || '--'}}</div>
								<div v-if="record.runing.cancel_reason">取消理由：{{record.runing.cancel_reason}}</div>
							</template>
							<a-tag color="#999" v-if="record.runing.run_type == 'ziying'">商家配送</a-tag>
							<a-tag color="#999" v-if="record.runing.run_type == 'dada'">达达配送</a-tag>
						</a-tooltip>
						
						<p v-if="record.runing.persion">配送员：{{record.runing.persion.name}} ,{{record.runing.persion.mobile}}</p>
						<div v-if="record.runing.cancel_reason" style="color: red;">取消理由：{{record.runing.cancel_reason}}</div>
					</div>
				</template>
				<template #time="{record}">
					<div>{{record.send_time || "未定"}}</div>
				</template>
				<template #get="{record}">
					<div>{{record.name}} / {{record.phone }}  {{record.address}}</div>
				</template>
				<template #action="{record}">
					<a-space>
						<template v-if="record.delivery_status == 0 " v-has="{action:'wxapp_adopt_order_send',plat:isShop}">
							<com-deliver-goods
								v-if="(record.id == show.delivery_cycle )  && detail.is_refund !=2"
								btn-type="primary"
								btn-size="small" 
								btn-text="立即发货"
								:id="record.id" 
								:type="3" 
								:is-shop="isShop" 
								@success="()=>{getAdoptOrderDetail()}">
							</com-deliver-goods>
							<a-button v-else type="primary" disabled size="small">立即发货</a-button>
						</template>
						<a-button
							v-has="{action:'wxapp_adopt_order_confirm',plat:isShop}" 
							v-if="record.delivery_status == 1 " 
							type="primary" 
							@click="confirmCycleOrder(record.id)" 
							size="small">确认收货
						</a-button>
						<span
							v-has="{action:'wxapp_order_logistics',plat:isShop}" 
							v-if="record.delivery_status>=1">
							<com-logistics :order-type="3" :pk="record.id" btn-size="small" btn-type="primary" btn-text="查看物流"></com-logistics>
						</span>
						<template v-if="record.delivery_status==0 && isShop == 0 && (record.id == show.delivery_cycle )  && detail.is_refund !=2">
							<com-print-face-order
								btn-type="primary"
								btn-size="small"
								:type="3" 
								:order-id="record.id"  
								@close="getAdoptOrderDetail()">
							</com-print-face-order>
						</template>
					</a-space>
				</template>
			</a-table>
			<div class="kd-pacel" style="margin-top: 24px;">订单日志</div>
			<a-table :pagination="false" row-key="id" :data-source="orderLog" size="small" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'操作说明',dataIndex:'content'},
				{title:'操作时间',dataIndex:'create_time'},
			]" >
			</a-table>
		</div>
		
		<a-modal v-model:visible="show.adopt_number" title="修改认养编号" @ok="saveAdoptNumber" width="500px" @cancel="show.adopt_number = false">
			<a-input v-model:value="updateAdoptForm.adopt_number"></a-input>
		</a-modal>
		<a-modal v-model:visible="show.ripe_time" title="更新认养预计成熟时间" @ok="saveRipeTime" width="500px" @cancel="show.ripe_time = false">
			<a-date-picker v-model:value="updateAdoptForm.ripe_time" />
		</a-modal>
		<a-modal v-model:visible="show.status_list" title="状态跟踪" :footer="null" @cancel="show.status_list = false" width="700px">
			<a-timeline>
			    <a-timeline-item v-for="(item,index) in statusInfo.list" :key="index">
					<div>
						<a-button size="small" type="link" @click="delStatus(item.id)">删除</a-button>
						{{item.description}}
					</div>
					<div class="cpl-imgs">
						<a-image style="width: 50px;height: 50px;margin-right: 12px;" 
						v-for="(val,ind) in item.src_show" :key='ind' :src="val" />
					</div>
				</a-timeline-item>
			</a-timeline>
		</a-modal>
		
		<a-modal v-model:visible="show.status_add" title="新增状态信息跟踪" @ok="saveStatusForm" width="700px" @cancel="show.status_add= false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="信息说明">
					<a-input v-model:value="statusForm.remark"></a-input>
				</a-form-item>
				<a-form-item label="生长阶段">
					<a-select v-model:value="statusForm.maturity">
						<a-select-option value="">请选择生长阶段</a-select-option>
						<a-select-option value="报名中">报名中</a-select-option>
						<a-select-option value="认养中">认养中</a-select-option>
						<a-select-option value="已成熟">已成熟</a-select-option>
						<a-select-option value="配送中">配送中</a-select-option>
						<a-select-option value="已完成">已完成</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="工单类型">
					<a-cascader 
						v-model:value="cascaderValue" 
						:options="adoptServer"
						:fieldNames="{
							value:'id',
							label:'title',
							children:'details'
						}"
						placeholder="工单类型" 
						@change="changeServer"
					/>
					
				</a-form-item>
				<a-form-item label="图片信息">
					<kd-img-select :mutil="true" :src="statusForm.images" @success="(url)=>{ statusForm.images = url }"></kd-img-select>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="show.delivery_add" title="添加周期信息" @ok="addCycleOrder" width="700px" @cancel="show.delivery_add= false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="收货人">
					<a-input v-model:value="cycleForm.name"></a-input>
				</a-form-item>
				<a-form-item label="联系电话">
					<a-input v-model:value="cycleForm.phone"></a-input>
				</a-form-item>
				<a-form-item label="收货地址">
					<a-input v-model:value="cycleForm.address" disabled></a-input>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="show.refund" title="退款" @ok="refundOrder" width="400px" @cancel="show.refund= false">
			<a-input v-model:value="refundPrice"></a-input>
		</a-modal>

		
	</div>
</template>

<script>
import comCashbackStatus from '@/components/miniapp/com-cashback-status.vue'
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comSelectGbdevice from '@/components/layout/live/com-select-gbdevice.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import adoptModel from '@/api/adopt.js'
import common from '@/api/common.js'
export default{
	name:'page-order-detail',
	components:{
		comCashbackStatus,
		comSetRemark,
		comPayResult,
		comDeliverGoods,
		comLogistics,
		comPrintFaceOrder,
		comSelectGbdevice
	},
	props:{
		isShop:Number
	},
	setup(){
		const _d = reactive({
			active:1,
			order_id:0,
			detail:null,	//订单详情
			show:{
				delivery_add:false,	//添加周期信息
				delivery_index:0,	//当前配送周期
				delivery_cycle:0,	//某一期进行中
				server_index:0,
				cashback:0,			//订单返现状态
				adopt_number:false,	//修改认养信息
				ripe_time:false,	//预计成熟时间
				status_list:false,	//状态跟踪信息
				status_add:false,
				refund:false,
			},
			updateAdoptForm:{
				pk : "",
				ripe_time:"",			//预计成熟时间
				adopt_number :"",		//认养编号
			},
			statusInfo:{				//状态跟踪信息
				list:[],
				page:1,
				limit:10
			},
			statusForm:{
				adopt_id:0,
				order_id:0,
				maturity:"",
				images:[],
				server_id:0,
				server_detail_id:0,
				remark:"",
			},
			adoptServer:[],				//认养服务信息
			delivery:[],				//配送信息
			deliveryForm:{
				id:0,
				reback:false,
			},
			cycleForm:{
				lng:"",
				lat:"",
				name:"",
				phone:"",
				address:"",
				detail_id:0,
			},
			orderLog:[],
			cashback:0,	//返现状态
			cascaderValue:[],
			refundPrice:0,
			delivery_num:""
		})

		const opt = useRoute().query
		if( opt.order_id ) getAdoptOrderDetail()

		function getAdoptOrderDetail(){
			adoptModel.getAdoptOrderDetail(opt.order_id,res=>{
				if( res.pay_status  == 0 ) _d.active =1
				if( res.pay_status  == 1 ) _d.active =2
				if( res.order_status  == 3 ) _d.active =3
				if( res.is_confirm  == 1 ) _d.active =4

				_d.detail = res
				if( _d.detail.address_info ){
					let add = JSON.parse(_d.detail.address_info)
					_d.cycleForm.name = add.name
					_d.cycleForm.phone = add.phone
					_d.cycleForm.address = add.address
					_d.cycleForm.lng = add.lng
					_d.cycleForm.lat = add.lat
				}
				getAdoptOrderlog()
				changeDelivery(res.detail[0])
			})
		}

		function getAdoptOrderlog(){
			common.getOrderLog('adopt_order',opt.order_id,res=>_d.orderLog = res)
		}

		function updateAdoptNumber(data){
			_d.updateAdoptForm.pk = data.id
			_d.updateAdoptForm.adopt_number = data.adopt_number
			_d.show.adopt_number = true
		}

		function updateRipeTime(data){
			_d.updateAdoptForm.ripe_time = data.ripe_time
			_d.updateAdoptForm.pk = data.id
			_d.show.ripe_time = true
		}

		function getStatusList(id){
			_d.updateAdoptForm.pk = id
			_d.show.status_list = true
			adoptModel.getAdoptStatus(id,res=>_d.statusInfo.list = res)
		}

		function addAdoptStatus(data){
			_d.statusForm = {
				adopt_id:_d.detail.adoption_id,
				order_id:data.id,
				maturity:"",
				images:[],
				server_id:0,
				server_detail_id:0,
				remark:"",
			}
			_d.show.status_add = true
			adoptModel.getAdoptServe(_d.detail.adoption_id,res=>_d.adoptServer = res)
		}

		function changeServer(e){
			_d.statusForm.server_id = e[0]
			_d.statusForm.server_detail_id = e[1]
		}

		function changeDelivery(row){
			_d.delivery = row.delivery
			_d.delivery_num = row.adopt_number
			let { delivery } = _d ,current_adopt = 0
			for( let i =0 ; i<delivery.length ; i++ ){
				if( delivery[i].delivery_status == 0 ){
					current_adopt  = delivery[i].id
					break
				}
			}
			_d.show.delivery_cycle  = current_adopt
		}

		function addCycleOrder(){
			let cycleForm = JSON.parse(JSON.stringify(_d.cycleForm))
			cycleForm.order_id = opt.order_id
			cycleForm.detail_id = _d.delivery[0].detail_id
			
			adoptModel.createAdoptCycle(cycleForm,()=>{
				_d.show.delivery_add = false
				getAdoptOrderDetail()
			})
		}

		const printOrder = ()=>common.printOrder(opt.order_id,3)
		const refundOrder = ()=>adoptModel.adoptOrderRefund(_d.refundPrice,opt.order_id,()=>{
			_d.show.refund = false
			getAdoptOrderDetail()
		})

		const saveAdoptNumber =()=>adoptModel.setAdoptOrderInfo(_d.updateAdoptForm,null,()=>{
			_d.show.adopt_number = false 
			getAdoptOrderDetail()
		})
		const changeAdoptStatus =(pk,adoption_status)=>{
			adoptModel.setAdoptOrderInfo({pk,adoption_status},'确认要修改该认养的状态吗?',()=>getAdoptOrderDetail())
		}

		const saveRipeTime = ()=>adoptModel.setAdoptOrderInfo(_d.updateAdoptForm,null,()=>{
			_d.show.ripe_time = false
			getAdoptOrderDetail()
		})

		const saveStatusForm = ()=>adoptModel.addOrEditAdoptStatus(_d.statusForm,()=>_d.show.status_add = false)

		const delStatus = (id)=>common.deleteDataList(id,33,'确认删除认养跟踪状态信息吗?').then(()=>getStatusList(_d.updateAdoptForm.pk))
		
		const confirmCycleOrder = (id)=>adoptModel.handleAdoptOrder(id,3,'确认该订单已经收货了吗',()=>getAdoptOrderDetail())
		const downloadContract = ()=>adoptModel.getContractOrder(opt.order_id)
		return{
			...toRefs(_d),
			getAdoptOrderDetail,
			getAdoptOrderlog,
			printOrder,
			refundOrder,
			updateAdoptNumber,
			saveAdoptNumber,
			changeAdoptStatus,
			updateRipeTime,
			getStatusList,
			addAdoptStatus,
			changeServer,
			saveStatusForm,
			delStatus,
			changeDelivery,
			confirmCycleOrder,
			addCycleOrder,
			saveRipeTime,
			downloadContract
		}
	},
}
</script>
<style lang="scss">
.order-steps{
	width: 80%;
	margin: auto;
}
.order-info{
	border: 1px solid #f4f4f4;
	width: 90%;
	margin: auto;
	margin-top: 20px;
	font-size: 12px;
	line-height: 30px;
}
.order-li{
	display: flex;
}
.order-li .order-title{
	width: 70px;
	color: #868686;
}
.order-status{
	color: orange;
	font-size: 20px;
	font-weight: bold;
}

.opeartionSpan{
	color: #0074D9;
	cursor: pointer;
}
</style>
